import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.1_@types+node@22.13.1_@unocss+preset-wind@65.4.3_@_ft5pwiakdyhuqurxpk4am75izi/node_modules/@una-ui/content/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}