import revive_payload_client_2dWLESIX6i from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5QTGqJxwKP from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_w6cXe7EGye from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_SCpIxYRjbO from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_vite@5.4.14_@types+node@22.13.1_terser@5.38.2__vue@3.5.13_typescript@5.6.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_BsBMYYEfAf from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hkX6VDWtaU from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yOM6dpLqzc from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_OoBAaqFRaR from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/docs/.nuxt/components.plugin.mjs";
import prefetch_client_2sTCSlBxb7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_aws4fetch@1.0.20_db0@0.2.4_eslint@9.20._eu73gccpjaixpzsytpgedxw4cq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Ozodz4Uvzc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/docs/.nuxt/unocss.mjs";
import theme_client_fYbo1aX2ww from "/vercel/path0/node_modules/.pnpm/@una-ui+nuxt-edge@0.35.0-beta.1-28999038.ac4401c_@unocss+preset-wind@65.4.3_@unocss+webpack@6_t444t6j7pdtydzepe6fkv5zfdi/node_modules/@una-ui/nuxt-edge/dist/runtime/plugins/theme.client.js";
import plugin_QBKEuUdO1p from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@5.4.14_@types+node@22.13.1_terser@5.38.2__vue@3.5.13_typescript@5.6.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_lVGqD0WZAC from "/vercel/path0/node_modules/.pnpm/@nuxt+content@2.13.4_aws4fetch@1.0.20_db0@0.2.4_ioredis@5.5.0_magicast@0.3.5_nuxt@3.15.4_@par_fxebu6ziyi3vqikyfu7roafg34/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
export default [
  revive_payload_client_2dWLESIX6i,
  unhead_5QTGqJxwKP,
  router_w6cXe7EGye,
  _0_siteConfig_SCpIxYRjbO,
  payload_client_BsBMYYEfAf,
  navigation_repaint_client_hkX6VDWtaU,
  check_outdated_build_client_yOM6dpLqzc,
  chunk_reload_client_OoBAaqFRaR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2sTCSlBxb7,
  plugin_client_Ozodz4Uvzc,
  unocss_MzCDxu9LMj,
  theme_client_fYbo1aX2ww,
  plugin_QBKEuUdO1p,
  documentDriven_lVGqD0WZAC
]